// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "../../variables";
.footer {
  background-color: $secondary;
  color: #fff;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  bottom: 0;

  &__copyright {
    font-size: 15px;
    width: 450px;
    text-align: center;
  }
}
